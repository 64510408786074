import { EyeIcon, ShareIcon } from "@heroicons/react/24/outline";
import React, { useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Tooltip } from 'react-tooltip'
import { Dialog } from "../../../_ui/Dialog";
import { SectionRole } from "../../../types/graphql/SectionRole";
import { SectionSlot } from "../../../types/graphql/SectionSlot";
import { SectionSlotLead } from "../../../types/graphql/SectionSlotLead";
import { User } from "../../../types/graphql/User";
import { EditAvailabilities } from "../../EditAvailabilities";
import { useSchedule } from "../../ScheduleContext";

type PropTypes = {
  user?: User
  loading?: boolean
}

export const Schedule = ({ user, loading }: PropTypes) => {
  const { season } = useSchedule() || {}
  const [ availabilityDialogOpen, setAvailabilityDialogOpen ] = useState(false)

  const scheduleItems = useMemo(() => {
    return [
      ...(user?.acceptedSlots || []).map(item => ({...item, typename: 'SectionSlot'})),
      ...(user?.acceptedSlotLeads || []).map(item => ({...item, typename: 'SectionSlotLead'})),
    ].reduce((acc: any, item: (SectionSlot & {typename: string}) | (SectionSlotLead & {typename: string})) => {
        return {
          ...acc,
          [item.begin.slice(0, 10)]: [
            ...(acc[item.begin.slice(0, 10)] || []),
            item
          ].sort((a, b) => a.begin.localeCompare(b.begin))
        }
      }, {})
  }, [user?.acceptedSlotLeads, user?.acceptedSlots])

  const collapsedAcceptedSlots = useMemo(() => {
    return Object.keys(scheduleItems).reduce((acc: any, date) => {
      return {
        ...acc,
        // @ts-ignore
        [date]: (scheduleItems[date] || []).reduce((acc2: any, slot: (SectionSlot & {typename: string}) | (SectionSlotLead & {typename: string})) => {
          if (slot.begin === acc2[0]?.end && slot.sectionRole?.id === acc2[0]?.sectionRole?.id) {
            return [
              {
                ...acc2[0],
                id: acc2[0].id + slot.id,
                end: slot.end
              },
              ...(acc2.slice(1) || [])
            ]
          } else {
            return [
              slot,
              ...acc2
            ]
          }
        }, []).reverse()
      }
    }, {})
  }, [scheduleItems])

  const rolesWithShiftExemption = useMemo(() => {
    return [
      ...(user?.sectionRoles || []).filter(item => item.shiftExemption),
      ...((season?.sections || [])
          .filter(seasonSection => {
            return (user?.sections || []).findIndex(s => s.id === seasonSection.id) >= 0
          })
          .reduce((acc: SectionRole[], item) => {
            return [
              ...acc,
              ...(item.sectionRoles || []).filter(role => role.shiftExemption)
            ]
          }, [])
      )
    ].sort((a, b) => a.title.localeCompare(b.title))
  }, [season?.sections, user?.sectionRoles, user?.sections])

  // @ts-ignore
  return (
    <div className={'sm:grid sm:grid-cols-3 sm:gap-2'}>
      <div className={'text-[14px]'}>
        <div className={'flex items-center gap-2 cursor-pointer'} onClick={() => setAvailabilityDialogOpen(true)}>
          Schichten
          <EyeIcon className={'w-4 h-4'} />
        </div>

        <Dialog separator title={'Verfügbarkeiten'} open={availabilityDialogOpen} onClose={() => setAvailabilityDialogOpen(false)}>
          <EditAvailabilities userID={user?.id} readonly />
        </Dialog>
      </div>
      <div className={'col-span-2'}>
        <div className={'grid gap-2 text-sm'}>
          {loading && (
            <Skeleton width={150} />
          )}
          {!loading && Object.keys(collapsedAcceptedSlots).length === 0 && (
            <div>
              Noch keine Schichten zugeordnet
            </div>
          )}
          {Object.keys(collapsedAcceptedSlots).sort().map(date => (
            <div key={date}>
              <h6 className={'font-bold mb-1'}>
                {new Date(date).toLocaleDateString('de-DE', {weekday: "long", day: "2-digit", month: "2-digit"})}
              </h6>
              {collapsedAcceptedSlots[date].map((item: (SectionSlot & {typename: string}) | (SectionSlotLead & {typename: string})) => (
                <div
                  key={item.id}
                  className={'grid grid-cols-[105px,1fr] gap-8'}
                  data-tooltip-id={item.id}
                  data-tooltip-content={item.typename === 'SectionSlotLead' ? 'Schichtleitung' : ''}
                >
                  <div className={'flex gap-1 items-center'}>
                    {item.isFlexSlot && (
                      <ShareIcon className={'w-3 h-3'} />
                    )}
                    <span>
                      {new Date(item.begin?.replace(/-/g, '/')).toLocaleTimeString('de-DE', {hour: '2-digit', minute: '2-digit'})}
                    </span>
                    <span>-</span>
                    <span>
                      {new Date(item.end?.replace(/-/g, '/')).toLocaleTimeString('de-DE', {hour: '2-digit', minute: '2-digit'})}
                    </span>
                  </div>
                  <div>
                    {item.sectionRole?.title || ''}
                    {item.typename === 'SectionSlotLead' && (
                      <React.Fragment >
                        &nbsp;
                        <strong>*</strong>
                        <Tooltip id={item.id} />
                      </React.Fragment>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))}

          {rolesWithShiftExemption.length > 0 && (
            <div>
              <h6 className={'font-bold mb-1'}>
                Rollen ohne Schichten
              </h6>
              {rolesWithShiftExemption.map(role => (
                <div className={'grid grid-cols-[105px,1fr] gap-8'}>
                  {role.title}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
