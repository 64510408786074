import { Dialog, Transition } from '@headlessui/react'
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { Fragment, useCallback, useMemo } from 'react'
import { useMutation } from "urql";
import { updateUserWithMultiplePropertiesMutation } from "../../_lib/graphql/mutations";
import { User } from "../../types/graphql/User";
import { EditUserForm } from "../EditUser/EditUserForm";
import { useSnackbarPush } from "../SnackbarContext/SnackbarContext";

type PropTypes = {
  open: boolean,
  profile?: User
}

export const MissingProfileInformationDialog = ({open, profile}: PropTypes) => {
  const [, updateUserWithMultipleProperties] = useMutation(updateUserWithMultiplePropertiesMutation)
  const { pushSuccess, pushError } = useSnackbarPush()

  const isUpdateDialog = useMemo(() => {
    return profile?.requiresProfileUpdate
  }, [profile?.requiresProfileUpdate])

  console.log(isUpdateDialog)

  const propertiesToSubmit = useCallback((formUser: User) => {
    const items = Object.keys(formUser).filter(item => item !== 'id').reduce((acc: {[property: string]: string}[], property: string) => ([
      ...acc,
      // @ts-ignore
      { property, value: (formUser[property] as string).toString() }
    ]), [])

    return items
  }, [])


  const submitAction = useCallback((formUser: User) => {
    updateUserWithMultipleProperties({
      input: {
        userID: profile?.id,
        properties: {
          ...propertiesToSubmit(formUser)
        }
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        pushSuccess('Profil erfolgreich aktualisiert')
      }
    })
  }, [updateUserWithMultipleProperties, profile, propertiesToSubmit, pushError, pushSuccess])

  if (!profile) {
    return null
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                    <PencilSquareIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 pb-4 border-b border-gray-200 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {isUpdateDialog ? 'Bitte überprüfe deine Profilangaben' : 'Informationen benötigt'}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {isUpdateDialog ? (
                          <>
                            Bitte überprüfe, ob deine Daten noch korrekt sind.
                          </>
                        ) : (
                          <>
                            Bitte vervollständige dein Profil, um das Tool im Anschluss weiter nutzen zu können.<br />
                            Du kannst dein Profil jederzeit wieder über das Menü oben rechts in der Ecke bearbeiten.<br />
                          </>
                        )}
                      </p>
                    </div>
                  </div>

                  <div className={'pt-4'}>
                    <h3 className={'font-medium text-lg'}>Profil Informationen</h3>

                    <EditUserForm
                      user={profile}
                      submitAction={submitAction}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
