import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, UseQueryArgs } from "urql";
import {
  updateMembershipStatusMutation,
  updatePropertyAnswerMutation,
  updateUserWithMultiplePropertiesMutation
} from "../../_lib/graphql/mutations";
import { readOneMembershipQuery } from "../../_lib/graphql/queries";
import { Spinner } from "../../_ui";
import { Property } from "../../types/graphql/Property";
import { PropertyAnswer } from "../../types/graphql/PropertyAnswer";
import { User } from "../../types/graphql/User";
import { useSnackbarPush } from "../SnackbarContext/SnackbarContext";
import { CancelDialog } from "./CancelDialog";
import { EditMembershipView } from "./EditMembershipView";

type PropTypes = {
  membershipID?: string
  hideHeader?: boolean
  submitOnClick?: boolean
  includeStatus?: boolean
  hidePrivate?: boolean
  readonly?: boolean
  showInfotext?: boolean
  profile?: User
  showNotificationPermissions?: boolean
  submitAction?: (formData: any) => void
}

export const EditMembership = ({membershipID, submitOnClick, hideHeader, hidePrivate, includeStatus, submitAction, profile, readonly, showInfotext, showNotificationPermissions}: PropTypes) => {
  const navigate = useNavigate();
  const { pushSuccess, pushWarning, pushError } = useSnackbarPush()
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const [formData, setFormData] = useState({})
  const timeoutRef = useRef(2500)
  const formDataRef = useRef<any>({})

  const [, updateProperty] = useMutation(updatePropertyAnswerMutation)
  const [, updateUserWithMultipleProperties] = useMutation(updateUserWithMultiplePropertiesMutation)
  const [readMembershipResult, refetchMembership] = useQuery({
    query: readOneMembershipQuery,
    variables: {
      input: {
        membershipID
      }
    },
    pause: !membershipID
  } as UseQueryArgs)

  const [, executeUpdateMembershipStatus] = useMutation(updateMembershipStatusMutation)

  const cancelMembership = useCallback(() => {
    executeUpdateMembershipStatus({
      input: {
        membershipID,
        status: 'leave'
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        setCancelDialogOpen(false)
        pushSuccess('Mitgliedschaft beendet')
        navigate('../')
      }
    })
  }, [navigate, membershipID, executeUpdateMembershipStatus, pushError, pushSuccess])

  const handleCancelMembership = useCallback(() => {
    setCancelDialogOpen(true)
  }, [setCancelDialogOpen])

  useEffect(() => {
    setFormData(prev => ({
      ...readMembershipResult.data?.readOneMembership?.answers?.reduce((acc: any, answer: PropertyAnswer) => {
        return {
          ...acc,
          [answer.propertyID]: {
            answerID: answer.id,
            membershipID,
            propertyID: answer.propertyID,
            value: answer.propertyType === 'checkboxset'
              ? (answer.value || '').split(',')
              : answer.value
          }
        }
      }, {})
    }))
  }, [readMembershipResult.data, membershipID])

  const handleUpdate = useCallback((propertyID: string, value: boolean | string | string[]) => {
    updateProperty({
      input: {
        answerID: readMembershipResult.data?.readOneMembership?.answers.find((answer: PropertyAnswer) => answer.propertyID === propertyID)?.id,
        membershipID,
        propertyID,
        value: typeof value === 'object' ? value.join(',') : value
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        pushSuccess('Speichern erfolgreich')
      }
    })
  }, [membershipID, updateProperty, readMembershipResult, pushError, pushSuccess])

  const handleFormSubmit = useCallback(() => {
    if (submitAction) {
      const list: any[] = [];
      const notificationList: any[] = [];

      Object.values(formData).forEach((data: any) => {
        const originalAnswer = readMembershipResult.data?.readOneMembership?.answers?.find((answer: PropertyAnswer) => answer.id === data.answerID)?.value

        if (data.hasOwnProperty('propertyID')) {
          if (typeof data.value !== 'string' && data.value !== null) {
            const difference = (data.value || [])
              .filter((x: string) => !(originalAnswer || '').split(',').includes(x))
              .concat((originalAnswer || '').split(',').filter((x: string) => !(data.value || []).includes(x)));

            if (difference.length) {
              list.push(data)
            }
          } else {
            if (originalAnswer !== data.value) {
              list.push(data)
            }
          }
        } else {
          notificationList.push(data)
        }
      })

      console.log('LIST', list, notificationList)
      if (Object.keys(notificationList).length > 0) {
        updateUserWithMultipleProperties({
          input: {
            userID: profile?.id,
            properties: [
              ...notificationList.map(item => ({
                property: item.notificationKey,
                value: item.value ? '1' : '0'
              }))
            ]
          }
        }).then(res => {
          console.log(res.data?.updateUserWithMultipleProperties?.requiredNotificationPermissions)
          const missingNotificationoPermissions = (res.data?.updateUserWithMultipleProperties?.requiredNotificationPermissions || [])
            .reduce((acc: any, item: string) => {
              const property = item[0].toLowerCase() + item.slice(1)

              // @ts-ignore
              if (!res.data?.updateUserWithMultipleProperties?.[property]) {
                return [
                  ...acc,
                  item
                ]
              }

              return acc
            }, [])

          if (missingNotificationoPermissions?.length > 0) {
            pushError('Bitte überprüfe deine Benachrichtigungs-Einstellungen. Möglicherweise sind einige davon verpflichtend für Gruppen, in denen du Mitglied bist.')
          }

          if (res.error) {
            pushError('Fehler beim Abspeichern der Benachrichtigungs Einstellungen')
          } else {
            if (list.length) {
              submitAction(list)
            } else {
              refetchMembership()
            }
          }
        })
      } else if (list.length) {
        submitAction(list)
      } else {
        pushWarning('Keine Veränderung zum Speichern vorhanden')
      }
    }

    return null
  }, [submitAction, readMembershipResult.data, formData, updateUserWithMultipleProperties, profile, refetchMembership, pushError, pushWarning])

  const changeFormFieldValue = useCallback((propertyID: string, value: boolean | string | string[]) => {
    const propertyType = readMembershipResult.data?.readOneMembership?.userGroup.properties.find((p: Property) => p.id === propertyID)?.type

    if (propertyID.startsWith('notification')) {
      const counter = formDataRef.current[propertyID]?.counter + 1 || 1
      formDataRef.current = {
        ...formDataRef.current,
        [propertyID]: {
          value: value.toString(),
          counter
        }
      }

      setFormData((prev: any) => ({
        ...prev,
        [propertyID]: {
          notificationKey: propertyID,
          value
        }
      }))
    } else {
      if (['text', 'multitext'].includes(propertyType)) {
        const counter = formDataRef.current[propertyID]?.counter + 1 || 1
        formDataRef.current = {
          ...formDataRef.current,
          [propertyID]: {
            value: value.toString(),
            counter
          }
        }

        setFormData((prev: any) => ({
          ...prev,
          [propertyID]: {
            ...prev[propertyID],
            value
          }
        }))

        if (!submitAction) {
          setTimeout(() => {
            if (formDataRef.current[propertyID].value === value && formDataRef.current[propertyID].counter === counter) {
              handleUpdate(propertyID, value)
              timeoutRef.current = 2500
            }
          }, timeoutRef.current)
        }
      } else {
        // console.log('submitOnClick', submitOnClick)
        // console.log(typeof submitAction)
        // console.log(typeof submitAction !== 'undefined')
        //
        // console.log(propertyID, propertyType, submitAction)
        if (typeof submitAction !== 'undefined') {
          setFormData((prev: any) => ({
            ...prev,
            [propertyID]: {
              ...prev[propertyID],
              value
            }
          }))
        } else {
          handleUpdate(propertyID, value)
        }
      }
    }
  }, [submitAction, handleUpdate, readMembershipResult.data])

  if (!readMembershipResult.data) {
    if (readMembershipResult.fetching) {
      return (
        <Spinner />
      )
    }

    return (
      <div className={'my-4 px-4 text-gray-700 font-medium'}>
        Mitgliedschaft nicht gefunden
      </div>
    )
  }

  if (!membershipID) {
    return null
  }

  return (
    <>
      <EditMembershipView
        title={readMembershipResult.data?.readOneMembership?.userGroup?.title || ''}
        description={readMembershipResult.data?.readOneMembership?.userGroup?.welcomeMessage || ''}
        answers={readMembershipResult.data?.readOneMembership?.answers || []}
        fields={readMembershipResult.data?.readOneMembership?.userGroup?.properties || []}
        footnote={readMembershipResult.data?.readOneMembership?.userGroup?.footnote}
        readonly={readonly || readMembershipResult.data?.readOneMembership?.userGroup?.isReadonly || readMembershipResult.fetching}
        status={readMembershipResult.data?.readOneMembership?.status}
        formData={formData}
        changeFormFieldValue={changeFormFieldValue}
        handleCancelMembership={handleCancelMembership}
        submitAction={submitAction ? handleFormSubmit : undefined}
        hideHeader={hideHeader}
        includeStatus={includeStatus}
        hidePrivate={hidePrivate}
        submitOnClick={submitOnClick}
        showInfotext={showInfotext}
        showNotificationPermissions={showNotificationPermissions}
      />

      <CancelDialog
        open={cancelDialogOpen}
        onClose={() => setCancelDialogOpen(false)}
        cancelMembership={cancelMembership}
      />
    </>
  )
}
