import { gql } from '@urql/core'
import { allMembershipDetails, guestListItemDetailsFragment, oneGroupDetails, userDetails } from './fragments'

export const readMyselfQuery = gql`
  query ReadMyself {
    readMyself {
      id
      firstName
      surname
      email
      status
      birthday
      street
      zip
      city
      phone
      isDebugUser
      requiresProfileUpdate
      requiredNotificationPermissions
      profileImageUrl
      notificationGeneral
      notificationMarketing
      notificationBirthday
      notificationInvitations
      notificationSupport
      acceptScheduleAgreement
      currentSeasonCheckin {
        id
        arrivalDate
        arrivalTime
        departureDate
        departureTime
        acceptScheduleAgreement
      }
      navigationItems {
        name
        href
        icon
        permissions
      }
      memberships {
        ...allMembershipDetails
      }
      availabilitySlots {
        id
        begin
        end
        sectionRole {
          id
          title
        }
      }
      acceptedSlots {
        id
        begin
        end
        isFlexSlot
        sectionRole {
          id
          title
          shiftExemption
        }
      }
      acceptedSlotLeads {
        id
        begin
        end
        sectionRole {
          id
          title
        }
      }
      sectionLeads {
        id
      }
      sectionEditPermissions {
        id
      }
      sections {
        id
        title
        season
      }
      sectionRoles {
        id
        title
        season
        shiftExemption
      }
      permissions
    }
  }
  ${allMembershipDetails}
`;

export const readOneMembershipQuery = gql`
  query ReadOneMembership($input: ReadOneMembershipInput!) {
    readOneMembership(input: $input) {
      id
      status
      emptyRequiredNotificationFields
      statusLabels {
        id
        title
        color
      }
      answers {
        id
        propertyID
        propertyType
        value
      }
      userGroup {
        id
        title
        welcomeMessage
        footnote
        isReadonly
        statusLabels {
          id
          title
          color
        }
        properties {
          id
          title
          infotext
          type
          shorten
          isRequired
          isReadonly
          isPrivate
          propertyOptions {
            id
            title
            description
            limit
            disabled
          }
        }
      }
    }
  }
`;

export const readAllUsersQuery = gql`
  query ReadAllUsers {
    readAllUsers {
      id
      firstName
      surname
      email
      memberships {
        id
        status
        userGroup {
          id
          title
        }
      }
    }
  }
`;

export const readAllUserNamesQuery = gql`
  query ReadAllUserNames($input: ReadAllUsersInput) {
    readAllUsers(input: $input) {
      id
      firstName
      surname
    }
  }
`;

export const readAllGroupsQuery = gql`
  query ReadAllGroups {
    readAllGroups {
      id
      title
      membershipAmount
    }
  }
`

export const readOneGroupQuery = gql`
  query ReadOneGroup($input: ReadOneGroupInput!) {
    readOneGroup(input: $input) {
      ...oneGroupDetails
      title
      properties {
        id
        title
        type
        shorten
        isRequired
        isReadonly
        isPrivate
        propertyOptions {
          id
          title
          description
        }
      }
    }
  }
  ${oneGroupDetails}
`

export const readStatusLabelsByGroupQuery = gql`
  query ReadStatusLabelsByGroup($input: StatusLabelByGroupInput!) {
    readStatusLabelsByGroup(input: $input) {
      id
      title
      color
    }
  }
`

export const readOneUserQuery = gql`
  query ReadOneUser($input: ReadOneUserInput!) {
    readOneUser(input: $input) {
      id
      ...userDetails
      currentSeasonCheckin {
        id
        arrivalDate
        arrivalTime
        departureDate
        departureTime
        checkinTime
      }
      acceptedSlots {
        id
        begin
        end
        isFlexSlot
        sectionRole {
          id
          title
          section {
            id
            title
          }
        }
      }
      acceptedSlotLeads {
        id
        begin
        end
        sectionRole {
          id
          title
          section {
            id
            title
          }
        }
      }
      sectionLeads {
        id
        title
        season
      }
      sections {
        id
        title
        season
      }
      sectionRoles {
        id
        title
        season
        shiftExemption
      }
      memberships {
        id
        status
        statusLabels {
          id
          title
          color
        }
        userGroup {
          id
          title
          statusLabels {
            id
            title
            color
          }
        }
      }
    }
  }
  ${userDetails}
`

export const readOneUsersAvailabilityQuery = gql`
  query ReadOneUsersAvailability($input: ReadOneUserInput!) {
      readOneUser(input: $input) {
          id
          availabilitySlots {
              id
              begin
              end
              sectionRole {
                  id
                  title
              }
          }
      }
  }
`

export const readScheduleAgreementTextQuery = gql`
  query ReadScheduleAgreementText {
    readCurrentSeason {
      id
      scheduleAgreementText
    }
  }
`

export const readScheduleStatisticQuery = gql`
  query ReadScheduleStatistic {
    readCurrentSeason {
      id
      userWithSections
      userWithAvailabilities
      userWithAcceptedSlots
      totalSlotsWithoutTemplate
      totalUnacceptedSlots
      totalSlotHours
      acceptedSlotHours
      userAmountWithoutSlots
      userAmountWithoutSlots
      totalCheckedInSlots
    }
  }
`

export const readCurrentSeasonQuery = gql`
  query ReadCurrentSeason {
    readCurrentSeason {
      id
      title
      begin
      end
      isLatest
      constructionBegin
      constructionEnd
      dismantlingBegin
      dismantlingEnd
      scheduleActive
      lineupItems {
        id
        artist
        date
        begin
        end
      }
      sections {
        id
        title
        publicAccess
        sectionRoles {
          id
          title
          shiftExemption
        }
      }
      seasonCheckinItems {
        id
        title
      }
    }
  }
`

export const readOneSectionQuery = gql`
  query ReadOneSection($id: ID!) {
    readOneSection(filter: {id: {eq: $id}}) {
      id
      title
      publicAccess
      scheduleDates
      scheduleLeadDates
      sectionLeaders {
        id
        firstName
        surname
        phone
        email
        profileImageUrl
      }
      sectionRoles {
        id
        title
        shiftExemption
      }
    }
  }
`

export const readOneSectionAndRolesQuery = gql`
  query ReadOneSectionAndRoles($id: ID!) {
    readOneSection(filter: {id: {eq: $id}}) {
      id
      title
      publicAccess
      scheduleDates
      scheduleLeadDates
      sectionLeaders {
        id
        firstName
        surname
        phone
        email
        profileImageUrl
      }
      sectionRoles {
        id
        title
        shiftExemption
        userAvailabilities {
          id
          firstName
          surname
          acceptedSlots {
            id
            begin
            end
            sectionRoleTitle
            sectionRoleHashID
          }
          acceptedSlotLeads {
            id
            begin
            end
            sectionRoleTitle
          }
          availabilitySlots {
            id
            begin
            end
            sectionRoleHashID
          }
        }
      }
    }
  }
`

export const sectionSlotFragment = gql`
  fragment sectionSlot on SectionSlot {
    id
    acceptedUser {
      id
      firstName
      surname
    }
    sectionRole {
      id
    }
  }
`

export const readSectionSlotsPerDateBySectionQuery = gql`
  query ReadSectionSlotsPerDateBySection($input: ReadSectionSlotsPerDateBySectionInput) {
    readSectionSlotsPerDateBySection(input: $input) {
      ...sectionSlot
      begin
      end
      note
      useAsTemplate
      autoConfirm
      isPrivate
      queueActive
      isFlexSlot
      checkinTime
    }
  }
  ${sectionSlotFragment}
`

export const sectionLeadSlotFragment = gql`
  fragment sectionSlotLead on SectionSlotLead {
    id
    acceptedUser {
      id
      firstName
      surname
    }
  }
`

export const readSectionSlotsByRoleQuery = gql`
  query ReadSectionSlotsByRole($input: ReadSectionSlotsByRoleInput) {
    readSectionSlotsByRole(input: $input) {
      ...sectionSlot
      begin
      end
      note
      useAsTemplate
      autoConfirm
      isPrivate
      queueActive
      isFlexSlot
      checkinTime
    }
  }
  ${sectionSlotFragment}
`

export const readSectionLeadSlotsByRoleQuery = gql`
  query ReadSectionLeadSlotsByRole($input: ReadSectionLeadSlotsByRoleInput) {
    readSectionLeadSlotsByRole(input: $input) {
      ...sectionSlotLead
      begin
      end
      note
    }
  }
  ${sectionLeadSlotFragment}
`

export const readOneSectionRoleQuery = gql`
  query ReadOneSectionRole($roleID: ID!) {
    readOneSectionRole(filter: {id: {eq: $roleID}}) {
      id
      title
      userAvailabilities {
        id
        firstName
        surname
        acceptedSlots {
          id
          begin
          end
          sectionRole {
            id
            title
          }
        }
        availabilitySlots {
          id
          begin
          end
          sectionRole {
            id
          }
        }
      }
    }
  }
`

export const readOneSectionRoleLeadsQuery = gql`
  query ReadOneSectionRoleLeads($roleID: ID!) {
    readOneSectionRole(filter: {id: {eq: $roleID}}) {
      id
      title
      sectionSlotLeads {
        id
        begin
        end
      }
    }
  }
`

export const readAvailableGroupsQuery = gql`
  query ReadAvailableGroups($input: ReadAvailableGroupsInput!) {
    readAvailableGroups(input: $input) {
      id
      title
      longTitle
    }
  }
`

export const readSeasonsQuery = gql`
  query ReadSeasons {
    readSeasons {
      id
      title
      begin
      end
      isLatest
    }
  }
`

export const readCurrentSectionsAndRolesQuery = gql`
  query ReadCurrentSectionsAndRoles {
    readCurrentSeason {
      id
      sections {
        id
        title
        sectionRoles {
          id
          title
          description
        }
      }
    }
  }
`

export const readSectionRolesOfOneSectionQuery = gql`
  query ReadOneSection($id: ID!) {
    readOneSection(filter: {id: {eq: $id}}) {
      id
      title
      sectionRoles {
        id
        title
        description
      }
    }
  }
`

export const readUserNotesQuery = gql`
  query ReadUserNotes($input: ReadUserNotesInput!) {
    readUserNotes(input: $input) {
      id
      created
      author {
        id
        firstName
        surname
      }
      message
    }
  }
`

export const readDashboardSeasonQuery = gql`
  query ReadDashboardSeasonQuery {
    readCurrentSeason {
      id
      title
      isInTargetGroup
      targetGroupText
      targetGroupButtonText
    }
  }
`

export const readUserSearchQuery = gql`
  query ReadUserSearch($input: ReadUserSearchInput!) {
    readUserSearch(input: $input) {
      id
      firstName
      surname
      profileImageUrl
    }
  }
`

export const readSectionRolesForApplication = gql`
  query ReadSectionRolesForApplication {
    readSectionRolesForApplication {
      id
      title
      section {
        id
        title
      }
    }
  }
`

export const readSectionSlotsByRoleForApplicationQuery = gql`
  query ReadSectionSlotsByRoleForApplication($input: ReadSectionSlotsByRoleForApplicationInput) {
    readSectionSlotsByRoleForApplication(input: $input) {
      id
      begin
      end
      acceptedApplication
    }
  }
`

export const readOneSectionRoleForApplication = gql`
  query ReadOneSectionRoleForApplication($roleID: ID!) {
    readOneSectionRole(filter: {id: {eq: $roleID}}) {
      id
      title
      description
      section {
        id
        sectionLeaders {
          id
          firstName
          surname
          phone
          email
          profileImageUrl
        }
      }
      sectionSlots {
        id
        begin
        end
      }
      emptySectionSlots {
        id
        begin
        end
        organisation
      }
    }
  }
`

export const readMyAvailabilitiesQuery = gql`
  query ReadMyAvailabilites {
    readMyself {
      id
      availabilitySlots {
        id
        begin
        end
        sectionRole {
          id
          title
        }
      }
    }
  }
`

export const readUserAvailabilitiesQuery = gql`
  query ReadOneUser($input: ReadOneUserInput!) {
    readOneUser(input: $input) {
      id
      availabilitySlots {
        id
        begin
        end
        sectionRole {
          id
          title
        }
      }
    }
  }
`

export const readOneAvailabilitySlotQuery = gql`
  query ReadOneAvailabilitySlot($input: ReadOneAvailabilitySlotInput!) {
    readOneAvailabilitySlot(input: $input) {
      id
      begin
      end
    }
  }
`

export const readGuestListForCurrentSeasonQuery = gql`
    query ReadGuestListForCurrentSeason {
      readGuestListForCurrentSeason {
        ...guestListItemDetails
      }
    }
    ${guestListItemDetailsFragment}
`

export const readMissingSectionRoleLeadsQuery = gql`
  query ReadMissingSectionRoleLeadsByDate($input: ReadMissingSectionRoleLeadsByDateInput!) {
    readMissingSectionRoleLeadsByDate(input: $input) {
      id
      title
      section {
        id
        title
      }
    }
  }
`

export const readEmptySectionRoleLeadsByDateQuery = gql`
  query ReadEmptySectionRoleLeadsByDate($input: ReadEmptySectionRoleLeadsByDateInput!) {
    readEmptySectionRoleLeadsByDate(input: $input) {
      id
      begin
      end
      sectionRole {
        id
        title
        section {
          id
          title
        }
      }
    }
  }
`

export const readEmptySectionSlotsByDateQuery = gql`
  query ReadEmptySectionSlotsByDate($input: ReadEmptySectionSlotsByDateInput!) {
    readEmptySectionSlotsByDate(input: $input) {
      id
      begin
      end
      sectionRole {
        id
        title
        section {
          id
          title
        }
      }
    }
  }
`

export const readCheckinByUserIDQuery = gql`
  query ReadCheckinByUserID($input: ReadCheckinByUserIDInput!) {
    readCheckinByUserID(input: $input) {
      id
      checkinTime
      user {
        id
        firstName
        surname
        birthday
        profileImageUrl
        hasShiftExemption
        targetGroupRegistrationDate
        passes {
          id
          type
          checkinTime
        }
        acceptedSlots {
          id
          begin
          end
          sectionRole {
            id
            title
            section {
              id
            }
          }
        }
        acceptedSlotLeads {
          id
          begin
          end
          sectionRole {
            id
            title
            section {
              id
            }
          }
        }
      }
      seasonCheckinItems {
        id
        title
        checkinTime
      }
      checkinProperties {
        id
        title
        type
        propertyOptions {
          id
          title
        }
      }
      checkinPropertyAnswers {
        id
        propertyID
        value
      }
    }
  }
`

export const readSectionUsersQuery = gql`
  query ReadSectionUsers($sectionID: ID) {
    readSectionUsers(sectionID: $sectionID) {
      id
      firstName
      surname
      hasShiftExemption
      availabilitySlots {
        id
        begin
        end
        sectionRole {
          id
          title
          section {
            id
          }
        }
      }
      acceptedSlots {
        id
        begin
        end
        sectionRole {
          id
          title
          section {
            id
          }
        }
      }
      acceptedSlotLeads {
        id
        begin
        end
        sectionRole {
          id
          title
          section {
            id
          }
        }
      }
      sectionRoles {
        id
        title
        shiftExemption
        section {
          id
          title
        }
        season
      }
    }
  }
`

export const readUserMessagesQuery = gql`
  query ReadMyMessages {
    readMyself {
      id
      messagesSent {
        id
        subject
        content
        summary
        recipientType
      }
    }
  }
`

export const readOneMessageQuery = gql`
    query ReadOneMessage($messageID: ID!) {
      readOneMessage(filter: {id: {eq: $messageID}}) {
        id
        subject
        content
        recipientType
      }
    }
`

export const readSectionUsersOfAllSectionsQuery = gql`
  query ReadSectionUsersOfAllSections($sectionID: ID) {
    readSectionUsers(sectionID: $sectionID) {
      id
      firstName
      surname
      hasShiftExemption
      availabilitySlots {
        id
        begin
        end
        sectionRole {
          id
          title
          section {
            id
          }
        }
      }
      acceptedSlots {
        id
        begin
        end
        isFlexSlot
        sectionRole {
          id
          title
          section {
            id
          }
        }
      }
      acceptedSlotLeads {
        id
        begin
        end
        sectionRole {
          id
          title
          section {
            id
          }
        }
      }
    }
  }
`

export const readAllPassesQuery = gql`
    query ReadAllPasses {
      readAllPasses {
        id
        passID
        type
        organisation
        userType
        user {
          id
          firstName
          surname
        }
        firstName
        surname
        checkinTime
      }
    }
`

export const readOnePassQuery = gql`
    query ReadOnePass($passID: String!) {
      readOnePass(passID: $passID) {
        id
        passID
        type
        organisation
        userType
        user {
          id
          firstName
          surname
        }
        firstName
        surname
        checkinTime
      }
    }
`

export const readUserWithoutSlotsQuery = gql`
    query ReadUserWithoutSlots {
      readUserWithoutSlots {
        id
        firstName
        surname
        isInTargetGroup
        availabilitySlots {
          id
          begin
          end
          sectionRole {
            id
            title
            section {
              id
            }
          }
        }
      }
    }
`

export const readSectionSlotsByDayQuery = gql`
    query ReadSectionSlotsByDay($input: ReadSectionSlotsByDayInput) {
      readSectionSlotsByDay(input: $input) {
        id
        begin
        end
        checkinTime
        acceptedUser {
          id
          firstName
          surname
          profileImageUrl
        }
        sectionRole {
          id
          title
        }
      }
    }
`

export const readMissingSectionSlotsByDayQuery = gql`
    query ReadMissedSectionSlotsByDay($input: ReadMissedSectionSlotsByDayInput) {
      readMissedSectionSlotsByDay(input: $input) {
        id
        begin
        end
        checkinTime
        acceptedUser {
          id
          firstName
          surname
          profileImageUrl
        }
        sectionRole {
          id
          title
        }
      }
    }
`

export const readOneSectionSlotForCheckinQuery = gql`
    query ReadOneSectionSlotForCheckin($ID: String!) {
      readOneSectionSlot(ID: $ID) {
        id
        checkinTime
      }
    }
`

export const readAllSeasonCheckinsQuery = gql`
    query ReadAllSeasonCheckins {
      readCurrentSeason {
        id
        allUserCheckins {
          id
          checkinTime
          user {
            id
            firstName
            surname
          }
          seasonCheckinItems {
            id
            title
            checkinTime
          }
        }
      }
    }
`


