import { gql } from '@urql/core'

export const allMembershipDetails = gql`
  fragment allMembershipDetails on Membership {
    id
    userID
    statusLabels {
      id
      title
      color
    }
    answers {
      id
      propertyID
      propertyType
      value
    }
    emptyRequiredFields {
      id
      title
    }
    emptyRequiredNotificationFields
    userGroup {
      id
      title
      properties {
        id
        title
        type
        propertyOptions {
          id
          title
        }
      }
    }
  }
`

export const oneGroupDetails = gql`
  fragment oneGroupDetails on UserGroup {
    id
    membershipAmount
    membershipRequestAmount
    memberships {
      id
      userID
      firstName
      surname
      statusLabels {
        id
        title
        color
      }
      answers {
        id
        propertyID
        propertyTitle
        value
      }
    }
    membershipRequests {
      id
      userID
      firstName
      surname
      statusLabels {
        id
        title
        color
      }
      answers {
        id
        propertyID
        propertyTitle
        value
      }
    }
  }
`

export const userDetails = gql`
  fragment userDetails on User {
    firstName
    surname
    email
    status
    birthday
    street
    zip
    city
    phone
    userNoteAmount
    notificationGeneral
    notificationMarketing
    notificationBirthday
    notificationInvitations
    notificationSupport
    profileImageUrl
    hasLeaderPermissionsForUser
    requiresProfileUpdate
    requiredNotificationPermissions
  }
`

export const guestListItemDetailsFragment = gql`
  fragment guestListItemDetails on GuestListItem {
    id
    company
    firstName
    surname
    amount
    dates
    reason
    checkinNotice
    checkinTime
    user {
      id
      firstName
      surname
    }
    backstageAccess
    campingAccess
  }
`
