import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery, UseQueryArgs } from 'urql';
import {
  __PARTIAL__APP_CREW_SCHEDULE,
  __PARTIAL__APP_LEAD_SCHEDULE,
  APP_CREW_SCHEDULE,
  APP_LEAD_SCHEDULE
} from "../../_constants/permissions";
import { loginUserMutation, logoutUserMutation } from "../../_lib/graphql/mutations";
import { readMyselfQuery } from '../../_lib/graphql/queries';
import { Spinner } from "../../_ui";
import { Membership } from "../../types/graphql/Membership";
import { User } from "../../types/graphql/User";
import { Login } from "../Login";
import { MissingMembershipAnswersDialog } from "../MissingMembershipAnswersDialog";
import { MissingProfileInformationDialog } from "../MissingProfileInformationDialog";
import { useSchedule } from "../ScheduleContext";
import { useSnackbarPush } from "../SnackbarContext/SnackbarContext";

const REQUIRED_PROFILE_INFORMATION = [
  'city',
  'email',
  'firstName',
  'phone',
  'street',
  'surname',
  'zip'
]

interface MyselfContextType extends User {
  hasHelperSchedulePermissions?: boolean
  IsLoggedIn?: boolean,
  login: (email: string, password: string, rememberMe: boolean) => void
  logout?: () => void,
  canAccess: (code: string | string[]) => boolean
}

const MyselfContext = createContext<MyselfContextType | null>(null);

export const useMyself = () => useContext(MyselfContext)

type PropTypes = {
  children: React.ReactNode
}

const UserContextProvider = ({ children }: PropTypes) => {
  const [hasData, setHasData] = useState(true)
  const isFirstLoading = useRef(true)
  const { season } = useSchedule() || {}
  const { pushSuccess, pushError } = useSnackbarPush()

  const [myself, refetchUser] = useQuery({ query: readMyselfQuery } as UseQueryArgs);
  const [loginState, executeLoginUser] = useMutation(loginUserMutation)
  const [logoutState, executeLogoutUser] = useMutation(logoutUserMutation)

  useEffect(() => {
    if (!isFirstLoading.current) {
      setHasData(myself.data !== undefined && myself.data.readMyself)
    } else {
      isFirstLoading.current = false
    }
  }, [myself])

  const login = useCallback((email: string, password: string, rememberMe: boolean) => {
    executeLoginUser({
      input: {
        email,
        password,
        rememberMe
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        localStorage.setItem('token', res.data.loginUser?.access_token)
        localStorage.setItem('refresh_token', res.data.loginUser?.refresh_token)

        const time = Math.round(new Date().getTime() / 1000)
        const expiredTimestamp = time + parseInt(res.data.loginUser?.expiry || '', 10)
        localStorage.setItem('expiredTimestamp', expiredTimestamp.toString());
        pushSuccess('Login erfolgreich')
        refetchUser()
      }
    })
  }, [executeLoginUser, refetchUser, pushError, pushSuccess])

  // const submitLogin = async (email: string, password: string) => {
  //   const token = await getToken(email, password)
  //   if (token && !token.error) {
  //     const time = Math.round(new Date().getTime() / 1000)
  //     const expiredTimestamp = time + parseInt(token.expires_in, 10)
  //
  //     localStorage.setItem('token', token.access_token);
  //     localStorage.setItem('refreshToken', token.refresh_token);
  //     localStorage.setItem('expiredTimestamp', expiredTimestamp.toString());
  //
  //     refetchUser()
  //
  //     return token
  //   } else if (token.error) {
  //     console.log(token.error.message)
  //   }
  //
  //   localStorage.clear()
  //   setHasData(false)
  //   return false
  // }

  const logout = () => {
    executeLogoutUser().then(res => {
      if (res.data) {
        localStorage.removeItem('token')
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('expiredTimestamp')
        refetchUser()
      }
    })
  }

  const canAccess = useCallback((code: string | string[]) => {
    const currentMember = myself.data?.readMyself
    if (currentMember && currentMember.permissions) {
      if (currentMember.permissions.findIndex((permission: string) => permission && permission === 'ADMIN') >= 0) {
        return true
      }

      return typeof code === 'object'
        ? currentMember.permissions.findIndex((permission: string) => permission && code.includes(permission)) >= 0
        : currentMember.permissions.findIndex((permission: string) => permission && permission === code) >= 0
    }
    return false
  }, [myself])

  const missingRequiredFieldsGroup = useMemo(() => {
    if (myself.stale || myself.fetching) {
      return null
    }

    return myself.data?.readMyself?.memberships?.reduce((groupID: string | null, membership: Membership) => {
      return groupID || ((membership.emptyRequiredFields?.length || membership.emptyRequiredNotificationFields?.length) ? membership.id : null)
    }, null)
  }, [myself])

  const missingRequiredFieldsGroupTitle = useMemo(() => {
    return myself.data?.readMyself?.memberships?.reduce((groupTitle: string | null, membership: Membership) => {
      return groupTitle || (membership.emptyRequiredFields?.length ? membership.userGroup?.title || '' : null)
    }, '')
  }, [myself])

  const missingRequiredProfileInformation = useMemo(() => {
    if (!myself.data) {
      return false;
    }

    if (myself.data.readMyself?.requiresProfileUpdate) {
      return true
    }

    return REQUIRED_PROFILE_INFORMATION.reduce((acc, property) => {
      if (!myself.data?.readMyself?.[property] || myself.data?.readMyself?.[property] === '') {
        return true
      }

      return acc
    }, false)
  }, [myself])

  const hasHelperSchedulePermissions = useMemo(() => {
    if (canAccess(APP_CREW_SCHEDULE)
      || canAccess(APP_LEAD_SCHEDULE)
      || canAccess(__PARTIAL__APP_CREW_SCHEDULE)
      || canAccess(__PARTIAL__APP_LEAD_SCHEDULE)) {
      return true
    }

    if (season && (myself.data?.readMyself?.sections || myself.data?.readMyself?.sectionRoles)) {
      return (myself.data?.readMyself?.sections || []).filter((s: any) => s.season === season.id).length > 0
        || (myself.data?.readMyself?.sectionRoles || []).filter((s: any) => s.season === season.id).length > 0
    }

    return false
  }, [canAccess, myself.data?.readMyself?.sectionRoles, myself.data?.readMyself?.sections, season])

  useEffect(() => {
    const handleLogout = () => {
      setHasData(false)
    }

    document.addEventListener("loginError", () => handleLogout(), false)
  }, [])

  return (
    <MyselfContext.Provider
      value={{
        ...myself.data?.readMyself,
        IsLoggedIn: hasData,
        hasHelperSchedulePermissions,
        login,
        logout,
        canAccess
      }}
    >
      {/* TODO: new login handling */}
      {hasData
        ? (
          <>
            {children}
            <MissingMembershipAnswersDialog
              open={missingRequiredFieldsGroup !== null && !missingRequiredProfileInformation}
              title={missingRequiredFieldsGroupTitle}
              membershipID={missingRequiredFieldsGroup}
            />
            <MissingProfileInformationDialog
              open={missingRequiredProfileInformation}
              profile={myself.data?.readMyself}
            />
          </>
        ) : <Login />
      }
      {(myself.stale || myself.fetching || logoutState.fetching || loginState.fetching) && (
        <div className={'fixed top-0 w-screen h-full z-40 backdrop-blur-sm bg-black/30'}>
          <div className={'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'}>
            <Spinner size={'w-16 h-16'} />
          </div>
        </div>
      )}
    </MyselfContext.Provider>
  );
}

export default UserContextProvider;
