import { gql } from '@urql/core'
import { allMembershipDetails, guestListItemDetailsFragment, oneGroupDetails, userDetails } from './fragments'
import { sectionSlotFragment } from './queries'

// connected to websocket
export const updatePropertyAnswerMutation = gql`
  mutation UpdatePropertyAnswer($input: UpdatePropertyAnswerInput!) {
    updatePropertyAnswer(input: $input) {
      id
      value
    }
  }
`;

// connected to websocket
export const updateMultiplePropertyAnswersMutation = gql`
  mutation UpdateMultiplePropertyAnswers($input: [UpdatePropertyAnswerInput]!) {
    updateMultiplePropertyAnswers(input: $input) {
      id
      answers {
        id
        value
      }
      emptyRequiredFields {
        id
        title
      }
      emptyRequiredNotificationFields
    }
  }
`;

// connected to websocket
export const addUserToGroupMutation = gql`
  mutation AddUserToGroup($input: AddUserToGroupInput!) {
    addUserToGroup(input: $input) {
      ...oneGroupDetails
    }
  }
  ${oneGroupDetails}
`;

// connected to websocket
export const addMembershipMutation = gql`
  mutation AddMembership($input: AddMembershipInput!) {
    addMembership(input: $input) {
      id
      memberships {
        ...allMembershipDetails
      }
    }
  }
  ${allMembershipDetails}
`

// connected to websocket
export const updateUserMutation = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      ...userDetails
    }
  }
  ${userDetails}
`;

// connected to websocket
export const updateUserWithMultiplePropertiesMutation = gql`
  mutation UpdateUserWithMultipleProperties($input: UpdateUserWithMultiplePropertiesInput!) {
  updateUserWithMultipleProperties(input: $input) {
      id
      ...userDetails
    }
  }
  ${userDetails}
`;

// connected to websocket
export const updateMembershipStatusMutation = gql`
  mutation UpdateMembershipStatus($input: UpdateMembershipStatusInput!) {
    updateMembershipStatus(input: $input) {
      id
      memberships {
        id
        status
      }
    }
  }
`;

// connected to websocket
export const rejectMembershipMutation = gql`
  mutation RejectMembership($input: RejectMembershipInput!) {
    rejectMembership(input: $input) {
      id
      memberships {
        id
        status
      }
    }
  }
`;

export const loginUserMutation = gql`
  mutation LoginUser($input: LoginUserInput!) {
    loginUser(input: $input) {
      id
      access_token
      refresh_token
      expiry
    }
  }
`;

export const refreshTokenMutation = gql`
  mutation RefreshToken($refresh_token: String!) {
    refreshToken(refresh_token: $refresh_token) {
      id
      access_token
      refresh_token
      expiry
    }
  }
`

export const logoutUserMutation = gql`
  mutation LogoutUser {
    logoutUser
  }
`;

export const addScheduleSlotToRoleMutation = gql`
  mutation AddScheduleSlotToRole($input: AddScheduleSlotToRoleInput!) {
    addScheduleSlotToRole(input: $input) {
      id
      begin
      end
    }
  }
`

export const removeScheduleSlotFromRoleMutation = gql`
  mutation RemoveScheduleSlotFromRole($input: RemoveScheduleSlotFromRoleInput!) {
    removeScheduleSlotFromRole(input: $input) {
      ...sectionSlot
      begin
      end
      sectionRole {
        id
        userAvailabilities {
          id
          firstName
          surname
          acceptedSlots {
            id
            begin
            end
          }
          availabilitySlots {
            id
            begin
            end
          }
        }
      }
    }
  }
  ${sectionSlotFragment}
`

export const addLeadScheduleSlotToRoleMutation = gql`
  mutation AddScheduleSlotLeadToRole($input: AddScheduleSlotLeadToRoleInput!) {
    addScheduleSlotLeadToRole(input: $input) {
      id
      begin
      end
    }
  }
`

export const updateScheduleSlotMutation = gql`
  mutation UpdateScheduleSlot($input: UpdateScheduleSlotInput!) {
    updateScheduleSlot(input: $input) {
      id
      begin
      end
      note
      useAsTemplate
      autoConfirm
      isPrivate
      queueActive
      isFlexSlot
    }
  }
`

export const updateLeadScheduleSlotMutation = gql`
  mutation UpdateLeadScheduleSlot($input: UpdateLeadScheduleSlotInput!) {
    updateLeadScheduleSlot(input: $input) {
      id
      begin
      end
      note
      acceptedUser {
        id
        firstName
        surname
      }
    }
  }
`

export const addUserNoteMutation = gql`
  mutation AddUserNote($input: AddUserNoteInput!) {
    addUserNote(input: $input) {
      id
      created
      author {
        id
        firstName
        surname
      }
      message
    }
  }
`

// connected to websocket (membership)
export const changeStatusLabelForMembershipMutation = gql`
  mutation ChangeStatusLabelForMembership($input: ChangeStatusLabelForMembershipInput!) {
    changeStatusLabelForMembership(input: $input) {
      id
      statusLabels {
        id
        title
        color
      }
    }
  }
`

// connected to websocket (user)
export const updateProfileImageMutation = gql`
  mutation UpdateProfileImage($input: UpdateProfileImageInput!) {
    updateProfileImage(input: $input) {
      id
      profileImageUrl
    }
  }
`

// connected to websocket (user)
export const addSectionRoleToCurrentSeasonMutation = gql`
  mutation AddSectionRoleToCurrentSeason($input: AddSectionRoleToCurrentSeasonInput!) {
    addSectionRoleToCurrentSeason(input: $input) {
      id
      sectionRoles {
        id
        title
        season
      }
    }
  }
`

// connected to websocket (user)
export const addSectionToCurrentSeasonMutation = gql`
  mutation AddSectionToCurrentSeason($input: AddSectionToCurrentSeasonInput!) {
    addSectionToCurrentSeason(input: $input) {
      id
      sections {
        id
        title
        season
      }
      sectionRoles {
        id
        title
        season
      }
    }
  }
`

// connected to websocket (user)
export const addSectionLeadToCurrentSeasonMutation = gql`
  mutation AddSectionLeadToCurrentSeason($input: AddSectionLeadToCurrentSeasonInput!) {
    addSectionLeadToCurrentSeason(input: $input) {
      id
      sectionLeads {
        id
        title
        season
      }
      sections {
        id
        title
        season
      }
      sectionRoles {
        id
        title
        season
      }
    }
  }
`

export const updateStatusLabelMutation = gql`
  mutation UpdateStatusLabel($input: UpdateStatusLabelInput!) {
    updateStatusLabel(input: $input) {
      id
      title
      color
    }
  }
`

export const createStatusLabelForGroupMutation = gql`
  mutation CreateStatusLabelForGroup($input: CreateStatusLabelForGroupInput!) {
    createStatusLabelForGroup(input: $input) {
      id
      title
      color
    }
  }
`

export const resendRegistrationEmailMutation = gql`
  mutation ResendRegistrationEmail($input: ResendRegistrationEmailInput!) {
    resendRegistrationEmail(input: $input) {
      id
    }
  }
`

// connected to websocket
export const updateCheckinTimeForUserMutatioon = gql`
  mutation UpdateCheckinTimeForUser($input: UpdateCheckinTimeForUserInput!) {
    updateCheckinTimeForUser(input: $input) {
      id
      currentSeasonCheckin {
        id
        arrivalDate
        arrivalTime
        departureDate
        departureTime
      }
    }
  }
`

export const addAvailabilitySlotMutation = gql`
  mutation AddAvailabilitySlot($input: AddAvailabilitySlotInput!) {
    addAvailabilitySlot(input: $input) {
      id
      availabilitySlots {
        id
        begin
        end
      }
    }
  }
`

export const removeAvailabilitySlotMutation = gql`
  mutation RemoveAvailabilitySlot($input: RemoveAvailabilitySlotInput!) {
    removeAvailabilitySlot(input: $input) {
      id
      availabilitySlots {
        id
        begin
        end
      }
    }
  }
`

export const acceptUserForSectionSlotMutation = gql`
  mutation AcceptUserForSectionSlot($input: AcceptUserForSectionSlotInput!) {
    acceptUserForSectionSlot(input: $input) {
      id
      acceptedSlots {
        id
        begin
        end
        acceptedUser {
          id
        }
      }
    }
  }
`

export const acceptUserForSectionSlotAndDuplicateMutation = gql`
  mutation AcceptUserForSectionSlotAndDuplicate($input: AcceptUserForSectionSlotAndDuplicateInput!) {
    acceptUserForSectionSlotAndDuplicate(input: $input) {
      id
      acceptedUser {
        id
        acceptedSlots {
          id
        }
      }
      begin
      end
      note
      useAsTemplate
      autoConfirm
      isPrivate
      queueActive
      isFlexSlot
      sectionRole {
        id
      }
    }
  }
`

export const acceptAllUserForSlotAndDuplicateMutation = gql`
  mutation AcceptUserForSectionSlotAndDuplicate($input: AcceptAndDuplicateAllUserForSlotInput!) {
    acceptAndDuplicateAllUserForSlot(input: $input) {
      id
      acceptedSlots {
        id
        acceptedUser {
          id
        }
      }
    }
  }
`

export const dropUserForSectionSlotMutation = gql`
  mutation DropUserForSectionSlot($input: DropUserForSectionSlotInput!) {
    dropUserForSectionSlot(input: $input) {
      id
      acceptedSlots {
        id
      }
    }
  }
`

export const updateAvailabilitySlotMutation = gql`
  mutation UpdateAvailabilitySlot($input: UpdateAvailabilitySlotInput!) {
    updateAvailabilitySlot(input: $input) {
      id
      availabilitySlots {
        id
        begin
        end
      }
    }
  }
`

export const deleteAvailabilitySlotMutation = gql`
  mutation DeleteAvailabilitySlot($id: ID!) {
    deleteAvailabilitySlot(id: $id) {
      id
      availabilitySlots {
        id
        begin
        end
      }
    }
  }
`

export const updateSectionLeadMutation = gql`
  mutation UpdateSectionLeader($input: UpdateSectionLeaderInput!) {
    updateSectionLeader(input: $input) {
      id
      sectionLeaders {
        id
        firstName
        surname
        phone
        email
        profileImageUrl
      }
    }
  }
`

export const createGuestListItemMutation = gql`
  mutation CreateGuestListItem($input: CreateGuestListItemInput!) {
    createGuestListItem(input: $input) {
      ...guestListItemDetails
    }
  }
  ${guestListItemDetailsFragment}
`

export const updateGuestListItemMutation = gql`
  mutation UpdateGuestListItem($input: UpdateGuestListItemInput!) {
    updateGuestListItem(input: $input) {
      ...guestListItemDetails
    }
  }
  ${guestListItemDetailsFragment}
`

export const deleteGuestListItemMutation = gql`
  mutation DeleteGuestListItem($input: DeleteGuestListItemInput!) {
    deleteGuestListItem(input: $input) {
      ...guestListItemDetails
    }
  }
  ${guestListItemDetailsFragment}
`

export const checkinGuestListItemMutation = gql`
  mutation CheckinGuestList($input: CheckinGuestListInput!) {
    checkinGuestList(input: $input) {
      id
      checkinTime
    }
  }
`

export const checkinUserMutation = gql`
  mutation CheckinUser($input: CheckinUserInput!) {
    checkinUser(input: $input) {
      id
      checkinTime
      seasonCheckinItems {
        id
        title
        checkinTime
      }
    }
  }
`

export const changeMessagePropertyMutation = gql`
  mutation ChangeMessageProperty($input: ChangeMessagePropertyInput!) {
    changeMessageProperty(input: $input) {
      id
      subject
      content
      recipientType
    }
  }
`

export const confirmScheduleAgreementMutation = gql`
  mutation ConfirmScheduleAgreement {
    confirmScheduleAgreement {
      id
      currentSeasonCheckin {
        id
        acceptScheduleAgreement
      }
    }
  }
`

export const cancelScheduleSlotMutation = gql`
  mutation CancelScheduleSlot($input: CancelScheduleSlotInput!) {
    cancelScheduleSlot(input: $input) {
      id
      acceptedSlots {
        id
      }
    }
  }
`

export const splitScheduleSlotMutations = gql`
  mutation SplitScheduleSlot($input: SplitScheduleSlotInput!) {
    splitScheduleSlot(input: $input) {
      ...sectionSlot
      begin
      end
      note
      useAsTemplate
      autoConfirm
      isPrivate
      queueActive
      isFlexSlot
    }
  }
  ${sectionSlotFragment}
`

export const duplicateScheduleSlotMutations = gql`
  mutation DuplicateScheduleSlot($input: DuplicateScheduleSlotInput!) {
    duplicateScheduleSlot(input: $input) {
      ...sectionSlot
      begin
      end
      note
      useAsTemplate
      autoConfirm
      isPrivate
      queueActive
      isFlexSlot
    }
  }
  ${sectionSlotFragment}
`

export const addPassMutation = gql`
  mutation AddPass($input: AddPassInput!) {
    addPass(input: $input) {
      id
      passID
      type
      organisation
      userType
      user {
        id
        firstName
        surname
      }
      firstName
      surname
      checkinTime
    }
  }
`

export const updatePassMutation = gql`
  mutation UpdatePass($input: UpdatePassInput!) {
    updatePass(input: $input) {
      id
      passID
      type
      organisation
      userType
      user {
        id
        firstName
        surname
      }
      firstName
      surname
      checkinTime
    }
  }
`

export const checkinPassMutation = gql`
  mutation CheckinPass($input: CheckinPassInput!) {
    checkinPass(input: $input) {
      id
      passID
      checkinTime
    }
  }
`

export const checkinSlotMutation = gql`
  mutation CheckinSectionSlot($input: CheckinSectionSlotInput!) {
    checkinSectionSlot(input: $input) {
      id
      checkinTime
    }
  }
`

export const addWebPushSubscriptionMutation = gql`
  mutation AddWebPushSubscription($input: AddWebPushSubscriptionInput!) {
    addWebPushSubscription(input: $input)
  }
`

export const updateSectionRoleMutation = gql`
  mutation UpdateSectionRole($input: UpdateSectionRoleInput!) {
    updateSectionRole(input: $input) {
      id
      description
    }
  }
`
