import React, { Suspense } from 'react';
import { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider as URQLProvider } from 'urql';
import { Snackbar } from "./_features";
import { NotFound } from "./_features/NotFound";
import ScheduleContextProvider from "./_features/ScheduleContext";
import UserContext from "./_features/UserContext";
import { Websocket } from "./_features/Websocket";
import { Layout } from './_layout';
import GraphQLClientSilverstripe from './_lib/graphql/URQLClientSilverstripe';
import { Spinner } from "./_ui";

const AllUsersPage = React.lazy(() => import(/* webpackChunkName: "allUsersPage" */'./_pages/AllUsersPage')
  .then(({ AllUsersPage }) => ({default: AllUsersPage}))
);
const CrewPage = React.lazy(() => import(/* webpackChunkName: "crewPage" */'./_pages/CrewPage')
  .then(({ CrewPage }) => ({default: CrewPage}))
);
const CheckInPage = React.lazy(() => import(/* webpackChunkName: "checkinPage" */'./_pages/CheckInPage')
  .then(({ CheckInPage }) => ({default: CheckInPage}))
);
const DashboardPage = React.lazy(() => import(/* webpackChunkName: "dashboardPage" */'./_pages/DashboardPage')
  .then(({ DashboardPage }) => ({default: DashboardPage}))
);
const ExportPage = React.lazy(() => import(/* webpackChunkName: "exportPage" */'./_pages/ExportPage')
  .then(({ ExportPage }) => ({default: ExportPage}))
);
const EvaluationPage = React.lazy(() => import(/* webpackChunkName: "evaluationPage" */'./_pages/EvaluationPage')
  .then(({ EvaluationPage }) => ({default: EvaluationPage}))
);
const GroupsPage = React.lazy(() => import(/* webpackChunkName: "groupsPage" */'./_pages/GroupsPage')
  .then(({ GroupsPage }) => ({default: GroupsPage}))
);
const GuestListPage = React.lazy(() => import(/* webpackChunkName: "guestListPage" */'./_pages/GuestListPage')
  .then(({ GuestListPage }) => ({default: GuestListPage}))
);
const MessagesPage = React.lazy(() => import(/* webpackChunkName: "messagesPage" */'./_pages/MessagesPage')
  .then(({ MessagesPage }) => ({default: MessagesPage}))
);
const PassesPage = React.lazy(() => import(/* webpackChunkName: "passesPage" */'./_pages/PassesPage')
  .then(({ PassesPage }) => ({default: PassesPage}))
);
const SchedulePage = React.lazy(() => import(/* webpackChunkName: "schedulePage" */'./_pages/SchedulePage')
  .then(({ SchedulePage }) => ({default: SchedulePage}))
);
const ScheduleLeadingPage = React.lazy(() => import(/* webpackChunkName: "scheduleLeadingPage" */'./_pages/ScheduleLeadingPage')
  .then(({ ScheduleLeadingPage }) => ({default: ScheduleLeadingPage}))
);
const ScheduleOverviewPage = React.lazy(() => import(/* webpackChunkName: "scheduleOverviewPage" */'./_pages/ScheduleOverviewPage')
  .then(({ ScheduleOverviewPage }) => ({default: ScheduleOverviewPage}))
);
const ScheduleDetailsPage = React.lazy(() => import(/* webpackChunkName: "scheduleDetailsPage" */'./_pages/ScheduleDetailsPage')
  .then(({ ScheduleDetailsPage }) => ({default: ScheduleDetailsPage}))
);
const ScheduleApplicationPage = React.lazy(() => import(/* webpackChunkName: "scheduleApplicationPage" */'./_pages/ScheduleApplicationPage')
  .then(({ ScheduleApplicationPage }) => ({default: ScheduleApplicationPage}))
);
const MyFestivalPage = React.lazy(() => import(/* webpackChunkName: "myFestivalPage" */'./_pages/MyFestivalPage')
  .then(({ MyFestivalPage }) => ({default: MyFestivalPage}))
);
const StatusLabelEditPage = React.lazy(() => import(/* webpackChunkName: "statusLabelEditPage" */'./_pages/StatusLabelEditPage')
  .then(({ StatusLabelEditPage }) => ({default: StatusLabelEditPage}))
);
const ScheduleSectionPage = React.lazy(() => import(/* webpackChunkName: "scheduleSectionPage" */'./_pages/ScheduleSectionPage')
  .then(({ ScheduleSectionPage }) => ({default: ScheduleSectionPage}))
);
const SectionEditPage = React.lazy(() => import(/* webpackChunkName: "sectionRoleEditPage" */'./_pages/SectionEditPage')
  .then(({ SectionEditPage }) => ({default: SectionEditPage}))
);
const SectionRoleEditPage = React.lazy(() => import(/* webpackChunkName: "sectionRoleEditPage" */'./_pages/SectionRoleEditPage')
  .then(({ SectionRoleEditPage }) => ({default: SectionRoleEditPage}))
);
const SlotCheckinPage = React.lazy(() => import(/* webpackChunkName: "slotCheckinPage" */'./_pages/SlotCheckinPage')
  .then(({ SlotCheckinPage }) => ({default: SlotCheckinPage}))
);
const SettingsPage = React.lazy(() => import(/* webpackChunkName: "settingsPage" */'./_pages/SettingsPage')
  .then(({ SettingsPage }) => ({default: SettingsPage}))
);
const InstructionPage = React.lazy(() => import(/* webpackChunkName: "schedulePage" */'./_pages/InstructionPage')
  .then(({ InstructionPage }) => ({default: InstructionPage}))
);
const MissingScheduleSlotsPage = React.lazy(() => import(/* webpackChunkName: "missingScheduleSlotsPage" */'./_pages/MissingScheduleSlotsPage')
  .then(({ MissingScheduleSlotsPage }) => ({default: MissingScheduleSlotsPage}))
);

const App = () => {
  return (
    <BrowserRouter>
      <Snackbar>
        <URQLProvider value={GraphQLClientSilverstripe}>
          <Websocket>
            <ScheduleContextProvider>
              <UserContext>
                <SkeletonTheme>
                  <Layout>
                    <Suspense fallback={<Spinner />}>
                      <Routes>
                        <Route path={'/benutzer/*'} element={<AllUsersPage />} />
                        <Route path={'/crew/*'} element={<CrewPage pageLink={'/crew/'} />} />
                        <Route path={'/checkin/*'} element={<CheckInPage pageLink={'/checkin/'} />} />
                        <Route path={'/auswertung/*'} element={<EvaluationPage />} />
                        <Route path={'/gruppen/*'} element={<GroupsPage pageLink={'/gruppen/'} />} />
                        <Route path={'/nachrichten/*'} element={<MessagesPage pageLink={'/nachrichten/'} />} />
                        <Route path={'/schichtplan/bereich/*'} element={<ScheduleSectionPage pageLink={'/schichtplan/bereich/'} />} />
                        <Route path={'/schichtplan/helfer/:sectionID/details/*'} element={<ScheduleDetailsPage pageLink={'/schichtplan/'} />} />
                        <Route path={'/schichtplan/helfer/*'} element={<SchedulePage pageLink={'/schichtplan/helfer/'} />} />
                        <Route path={'/schichtplan/leitung/*'} element={<ScheduleLeadingPage pageLink={'/schichtplan/leitung/'} />} />
                        <Route path={'/schichtplan/auswertung/*'} element={<MissingScheduleSlotsPage pageLink={'/schichtplan/auswertung/'} />} />
                        <Route path={'/schichtplan/*'} element={<ScheduleOverviewPage pageLink={'/schichtplan/'} />} />
                        <Route path={'/mein-festival/bewerbung/*'} element={<ScheduleApplicationPage pageLink={'/mein-festival/bewerbung/'} overviewLink={'/mein-festival/'} />} />
                        <Route path={'/mein-festival/*'} element={<MyFestivalPage pageLink={'/mein-festival/'} />} />
                        <Route path={'/gaesteliste/*'} element={<GuestListPage pageLink={'/gaesteliste/'} />} />
                        <Route path={'/ausweise/*'} element={<PassesPage pageLink={'/ausweise/'} />} />
                        <Route path={'/verwaltung/bereiche/:sectionID/'} element={<SectionRoleEditPage pageLink={'/verwaltung/bereiche/'} />} />
                        <Route path={'/verwaltung/bereiche/'} element={<SectionEditPage pageLink={'/verwaltung/bereiche/'} />} />
                        <Route path={'/verwaltung/status/*'} element={<StatusLabelEditPage pageLink={'/verwaltung/status/'} />} />
                        <Route path={'/verwaltung/export/*'} element={<ExportPage pageLink={'/export/'} />} />
                        <Route path={'/verwaltung/*'} element={<SettingsPage pageLink={'/verwaltung/'} />} />
                        <Route path={'/schichten/*'} element={<SlotCheckinPage pageLink={'/schichten/'} />} />
                        <Route path={'/anleitung/*'} element={<InstructionPage pageLink={'/verwaltung/'} />} />
                        <Route index element={<DashboardPage />} />
                        <Route path={'*'} element={<NotFound />} />
                      </Routes>
                    </Suspense>
                  </Layout>
                </SkeletonTheme>
              </UserContext>
            </ScheduleContextProvider>
          </Websocket>
        </URQLProvider>
      </Snackbar>
    </BrowserRouter>
  );
}

export default App;
