import { Menu, Popover, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { ChatBubbleOvalLeftEllipsisIcon, UserIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import { APP_EDIT_USER, APP_RESEND_REGISTRATION_EMAIL, APP_USER_NOTES } from "../_constants/permissions";
import { MembershipNotes } from "../_features/MembershipNotes";
import { useMyself } from "../_features/UserContext";
import { classNames } from "../_helpers";

type PropTypes = {
  status?: string
  name: string
  position?: string
  email?: string
  fetching?: boolean
  editProfile?: () => void
  userID: string
  userNoteAmount: number
  profileImage: string
  resendRegistrationEmail: () => void
}

export const ProfileHeader = ({ status, name, position, email, fetching, editProfile, userID, userNoteAmount, profileImage, resendRegistrationEmail }: PropTypes) => {
  const { canAccess } = useMyself() || {}
  return (
    <div className="pb-6">
      <div className="h-10 bg-primary-300 sm:h-14" />
      <div className="-mt-4 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
        <div>
          <div className="-m-1 flex">
            <div className="inline-flex overflow-hidden rounded-lg border-4 border-white">
              {!profileImage ? (
                <div className="flex flex-col flex-shrink-0 items-center justify-center h-24 w-24 bg-gray-200 text-center text-xs text-gray-500 sm:text-base sm:h-40 sm:w-40 ">
                  <UserIcon className={'w-16 h-16'} />
                  <span className={''}>Kein Profilbild</span>
                </div>
              ) : (
                <img
                  className="h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40"
                  src={profileImage}
                  alt=""
                />
              )}
            </div>
          </div>
        </div>
        <div className="mt-6 sm:ml-6 sm:flex-1">
          <div>
            <div className="flex items-center gap-2">
              <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                {!fetching ? name : <Skeleton width={200} height={26} />}
              </h3>

              {canAccess && canAccess(APP_RESEND_REGISTRATION_EMAIL) && status === 'Registered' && (
                <span className="inline-flex items-center rounded-md bg-yellow-50 mr-2 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                Bestätigung ausstehend
              </span>
              )}
            </div>
            <p className="block text-sm text-gray-500">{position || <>&nbsp;</>}</p>
          </div>
          <div className="mt-5 flex flex-wrap space-y-0 space-x-3">
            <button
              type="button"
              onClick={() => window.open(`mailto:${email}`, '_blank')}
              disabled={!email}
              className={classNames(
                'inline-flex flex-1 w-full items-center justify-center rounded-md border border-primary px-4 py-2 text-sm font-medium text-primary shadow-sm hover:bg-primary-300 hover:text-white focus:outline-none',
                'disabled:bg-gray-50 disabled:border-gray-200 disabled:text-gray-400'
              )}
            >
              Nachricht
            </button>

            {((canAccess && canAccess(APP_EDIT_USER)) || status === 'Registered') && (
              <div className="ml-3 inline-flex sm:ml-0">
                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button className="inline-flex items-center rounded-md border border-gray-300 bg-white p-2 text-sm font-medium text-gray-400 shadow-sm hover:bg-gray-50 focus:outline-none">
                    <span className="sr-only">Open options menu</span>
                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {canAccess && canAccess(APP_EDIT_USER) && (
                          <Menu.Item>
                            <button
                              onClick={editProfile}
                              className={'block w-full px-4 py-2 text-sm text-gray-700 leading-tight hover:bg-gray-100'}
                            >
                              Profil bearbeiten
                            </button>
                          </Menu.Item>
                        )}
                        {status === 'Registered' && (
                          <Menu.Item>
                            <button
                              onClick={resendRegistrationEmail}
                              className={'block w-full px-4 py-2 text-sm text-gray-700 leading-tight hover:bg-gray-100'}
                            >
                              Registrierungs E-Mail senden
                            </button>
                          </Menu.Item>
                        )}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            )}

            {canAccess && canAccess(APP_USER_NOTES) && (
              <div className="ml-3 inline-flex sm:ml-0">
                <Popover className="relative inline-block text-left">
                  <Popover.Button className="inline-flex items-center rounded-md border border-gray-300 bg-white p-2 text-sm font-medium text-gray-400 shadow-sm hover:bg-gray-50 focus:outline-none">
                    <span className="sr-only">Open options menu</span>
                    <ChatBubbleOvalLeftEllipsisIcon className="h-5 w-5" aria-hidden="true" />
                    {userNoteAmount > 0 && (
                      <span className={'absolute flex top-0 right-0 translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full border border-primary bg-white w-[18px] h-[18px] text-[10px] text-primary'}>
                      {userNoteAmount}
                    </span>
                    )}
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Popover.Panel className="absolute right-0 z-10 mt-2 w-96 max-w-[calc(100vw-73px)] origin-top-right overflow-scroll max-h-[600px] rounded-md bg-white shadow-2xl ring-2 ring-gray-300 focus:outline-none">
                      <MembershipNotes
                        userID={userID}
                      />
                    </Popover.Panel>
                  </Transition>
                </Popover>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
