import { useCallback, useState } from "react";
import { useMutation, useQuery } from "urql";
import { addUserNoteMutation } from "../../_lib/graphql/mutations";
import { readUserNotesQuery } from "../../_lib/graphql/queries";
import { Note } from "../../types/graphql/Note";
import { useSnackbarPush } from "../SnackbarContext/SnackbarContext";
import { MembershipNotesView } from "./MembershipNotes";

type PropTypes = {
  userID: string
}

export const MembershipNotes = ({userID}: PropTypes) => {
  const [message, setMessage] = useState('')
  const { pushSuccess, pushError } = useSnackbarPush()

  const [userNotesResult] = useQuery({
    query: readUserNotesQuery,
    variables: {
      input: {
        userID
      }
    },
    pause: !userID || userID === ''
  })

  const options = {
    hourCycle: 'h23',
    month: "numeric",
    year: "numeric",
    day: "numeric",
    hour: '2-digit',
    minute: '2-digit',
    second: "numeric",
    timeZone: "Europe/Berlin",
  };
  // @ts-ignore
  console.log(new Intl.DateTimeFormat("en-GB", options).format(new Date()));

  const [mutationState, addNote] = useMutation(addUserNoteMutation)

  const submitNote = useCallback(() => {
    addNote({
      input: {
        userID,
        message
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        pushSuccess('Notiz gespeichert')
        setMessage('')
      }
    })
  }, [addNote, userID, message, pushSuccess, pushError])

  return (
    <MembershipNotesView
      notes={(userNotesResult.data?.readUserNotes || []).sort((a: Note, b: Note) => (a?.created > b?.created) ? -1 : 1)}
      message={message}
      setMessage={setMessage}
      submitNote={submitNote}
      fetching={mutationState.fetching}
    />
  )
}
