import { authExchange } from '@urql/exchange-auth';
import { refreshTokenMutation } from '../mutations';

async function initializeAuthState() {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refresh_token');

  return { token, refreshToken };
}

const exchange = authExchange(async utils => {
  let token = localStorage.getItem('token');
  let refreshToken = localStorage.getItem('refresh_token');

  return {
    addAuthToOperation(operation) {
      if (token !== localStorage.getItem('token') || refreshToken !== localStorage.getItem('refresh_token')) {
        token = localStorage.getItem('token');
        refreshToken = localStorage.getItem('refresh_token');
      }

      if (!token || !refreshToken) {
        token = localStorage.getItem('token');
        refreshToken = localStorage.getItem('refresh_token');

        if (!token) {
          return operation;
        }
      }
      return utils.appendHeaders(operation, {
        Authorization: `Bearer ${token}`,
      });
    },
    didAuthError(error, _operation) {
      return error.graphQLErrors.some(
        e => e.message.includes('Invalid token')
          || e.message.includes('The resource owner or authorization server denied the request')
      )
    },
    async refreshAuth() {
      const data = await initializeAuthState();

      console.log('REFRES', data)
      if (data.refreshToken) {
        await utils.mutate(refreshTokenMutation, {
          refresh_token: data.refreshToken,
        }).then(result => {
          if (result.data?.refreshToken) {
            token = result.data.refreshToken?.access_token;
            refreshToken = result.data.refreshToken?.refresh_token;

            // save the new tokens in storage for next restart
            localStorage.setItem('token', token);
            localStorage.setItem('refresh_token', refreshToken);

            const time = Math.round(new Date().getTime() / 1000)
            const expiredTimestamp = time + parseInt(result.data.refreshToken?.expiry, 10)
            localStorage.setItem('expiredTimestamp', expiredTimestamp)
          } else {
            token = null
            refreshToken = null
          }
        })
      }
    },
    willAuthError: (operation) => {
      const token = localStorage.getItem('token')

      if (!token) {
        return true
      }

      // e.g. check for expiration, existence of auth etc
      const currTime = Math.round(new Date().getTime() / 1000)
      const tokenEndOfLife = parseInt(localStorage.getItem('expiredTimestamp'), 10)

      console.log(currTime, tokenEndOfLife, currTime > tokenEndOfLife)

      if (
        operation.kind === 'mutation' &&
        // Here we find any mutation definition with the "login" field
        operation.query.definitions.some(definition => {
          return (
            definition.kind === 'OperationDefinition' &&
            definition.selectionSet.selections.some(node => {
              // The field name is just an example, since signup may also be an exception
              return node.kind === 'Field' && ['createToken', 'logout'].includes(node.name.value);
            })
          );
        })
      ) {
        return false;
      } else {
        return currTime > tokenEndOfLife;
      }
    },
  };
});

export default exchange
