import { Menu, Transition } from "@headlessui/react";
import { LinkIcon } from "@heroicons/react/20/solid";
import { Bars3BottomLeftIcon, CheckIcon, QrCodeIcon } from "@heroicons/react/24/outline";
import React, { Dispatch, Fragment, SetStateAction, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  __PARTIAL__APP_SLOTCHECKIN,
  APP_CHECKIN,
  APP_SEARCH_USER,
  APP_SLOTCHECKIN,
  APP_SWITCH_SEASON
} from "../_constants/permissions";
import { EditUser } from "../_features/EditUser";
import { Searchbar } from "../_features/Searchbar";
import { SwitchSeasonDialog } from "../_features/SwitchSeasonDialog";
import { useMyself } from "../_features/UserContext";
import { useWebSocket } from "../_features/Websocket";

type PropTypes = {
  setMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
}

export const Header = ({setMobileMenuOpen}: PropTypes) => {
  const [editUserOpen, setEditUserOpen] = useState(false)
  const { id, firstName, surname, profileImageUrl, logout } = useMyself() || {};
  const { hasSocketConnection } = useWebSocket() || {}
  const { canAccess } = useMyself() || {}

  const [switchSeasonDialogOpen, setSwitchSeasonDialogOpen] = useState(false)

  const initials = useMemo(() => {
    return `${firstName?.slice(0, 1) || ''}${surname?.slice(0, 1) || ''}`
  }, [firstName, surname])

  return (
    <header className="w-full">
      <div className="relative z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white shadow-sm">
        <button
          type="button"
          className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-0 md:hidden"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex flex-1 justify-between px-4 sm:px-6">
          <div className="flex flex-1">
            {canAccess && canAccess(APP_SEARCH_USER) && <Searchbar />}
          </div>

          <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
            <div>
              {hasSocketConnection && (
                <LinkIcon className={'w-5 h-5 text-gray-400'} />
              )}
            </div>
            {/* Checkin */}
            {canAccess && canAccess(APP_CHECKIN) && !canAccess([APP_SLOTCHECKIN, __PARTIAL__APP_SLOTCHECKIN]) && (
              <Link to={'/checkin'} className={'flex items-center text-gray-700 text-sm gap-1'}>
                <QrCodeIcon className={'w-5 h-5'} />
                Check-In
              </Link>
            )}
            {canAccess && canAccess([APP_SLOTCHECKIN, __PARTIAL__APP_SLOTCHECKIN]) && !canAccess(APP_CHECKIN) && (
              <Link to={'/schichten'} className={'flex items-center text-gray-700 text-sm gap-1'}>
                <QrCodeIcon className={'w-5 h-5'} />
                Schicht Check-In
              </Link>
            )}
            {canAccess && canAccess(APP_CHECKIN) && canAccess([APP_SLOTCHECKIN, __PARTIAL__APP_SLOTCHECKIN]) && (
              <Menu as="div" className="relative flex items-center text-left">
                <Menu.Button
                  className="flex items-center text-gray-700 text-sm gap-1"
                  onClick={(e: any) => e.stopPropagation()}
                >
                  <div className={'flex items-center'}>
                    <QrCodeIcon className={'w-5 h-5'} />
                    Check-In
                  </div>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute top-0 right-0 z-10 mt-2 w-[170px] p-2 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <Menu.Item>
                      <Link to={'/checkin'} className={'flex items-center text-gray-700 text-sm gap-1 px-2 py-2'}>
                        <QrCodeIcon className={'w-5 h-5'} />
                        Festival Check-In
                      </Link>
                    </Menu.Item>

                    <Menu.Item>
                      <Link to={'/schichten'} className={'flex items-center text-gray-700 text-sm gap-1 px-2 py-2'}>
                        <CheckIcon className={'w-5 h-5'} />
                        Schicht Check-In
                      </Link>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            )}
            {/* Profile dropdown */}
            <Menu as="div" className="relative flex-shrink-0">
              <div>
                <Menu.Button className="flex rounded-full bg-primary-100 text-sm focus:outline-none">
                  {!profileImageUrl ? (
                    <>
                      <span className="sr-only">Open user menu</span>
                        <span
                          className="flex h-8 w-8 rounded-full text-white"
                        >
                        <span className={'w-6 h-6 m-auto leading-relaxed'}>
                          {initials}
                        </span>
                      </span>
                    </>
                  ) : (
                    <img
                      className="h-8 w-8 rounded-full"
                      src={profileImageUrl}
                      alt=""
                    />
                  )}
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    <button
                      onClick={() => setEditUserOpen(true)}
                      className={'w-full text-left flex items-center px-4 py-2 text-sm'}
                    >
                      Mein Profil
                    </button>
                  </Menu.Item>

                  <Menu.Item>
                    <button
                      onClick={() => window.location.reload()}
                      className={'w-full text-left flex items-center px-4 py-2 text-sm'}
                    >
                      Seite neu laden
                    </button>
                  </Menu.Item>

                  {/*<WebPushSubscription />*/}

                  {canAccess && canAccess(APP_SWITCH_SEASON) && (
                    <Menu.Item>
                      <button
                        onClick={() => setSwitchSeasonDialogOpen(true)}
                        className={'w-full text-left flex items-center px-4 py-2 text-sm'}
                      >
                        Saison wechseln
                      </button>
                    </Menu.Item>
                  )}

                  <Menu.Item>
                    <button
                      className={'w-full text-left flex items-center px-4 py-2 text-sm'}
                      onClick={logout}
                    >
                      Abmelden
                    </button>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>

            <SwitchSeasonDialog
              open={switchSeasonDialogOpen}
              onClose={() => setSwitchSeasonDialogOpen(false)}
            />

            <EditUser
              userID={id || ''}
              open={editUserOpen}
              onClose={() => setEditUserOpen(false)}
            />
          </div>
        </div>
      </div>
    </header>
  )
}
