import { cacheExchange } from '@urql/exchange-graphcache'
import {
  readAllPassesQuery,
  readGuestListForCurrentSeasonQuery,
  readOneUserQuery,
  readSectionLeadSlotsByRoleQuery,
  readSectionSlotsByRoleQuery,
  readSectionSlotsPerDateBySectionQuery,
  readUserNotesQuery,
  sectionSlotFragment
} from '../queries'

const cache = cacheExchange({
  schema: JSON.parse(window.localStorage.getItem('schema')),
  keys: {
    NavigationItem: data => data.href
  },
  resolvers: {
    Query: {
      readOneMembership: (parent, args, cache) => ({
        __typename: 'Membership',
        id: args.input.membershipID,
      }),
      readOneUser: (parent, args, cache) => ({
        __typename: 'User',
        id: args.input.userID,
      }),
      readOneGroup: (parent, args, cache) => ({
        __typename: 'UserGroup',
        id: args.input.groupID,
      }),
      readOneAvailabilitySlot: (parent, args, cache) => ({
        __typename: 'AvailabilitySlot',
        id: args.input.id,
      })
    },
    User: {
      permissions(parent, args, cache, info) {
        return parent.permissions?.split(',') || []
      },
    },
  },
  optimistic: {
    updatePropertyAnswer: (variables, cache, info) => {
      return {
        __typename: 'PropertyAnswer',
        id: variables.input.answerID,
        value: variables.input.value,
      }
    },
    confirmMembership: (variables, cache, info) => {
      return {
        __typename: 'Membership',
        id: variables.input.membershipID,
        status: 'active',
      }
    },
    addUserNote: (variables, cache, info) => {
      const myselfKey = cache.resolve('Query', 'readMyself')

      return {
        __typename: 'UserNote',
        id: 'unknown',
        author: myselfKey,
        created: new Date().toLocaleString('sv'),
        message: variables.input.message,
      }
    },
    changeStatusLabelForMembership: (args, cache, info) => {
      const statusLabels = cache.resolve(`Membership:${args.input.membershipID}`, 'statusLabels') || []

      return {
        __typename: 'Membership',
        id: args.input.membershipID,
        statusLabels: args.input.active
          ? [...statusLabels, `StatusLabel:${args.input.statusLabelID}`].filter((value, index, array) => array.indexOf(value) === index)
          : statusLabels.filter(item => item !== `StatusLabel:${args.input.statusLabelID}`)
      }
    },
    updateScheduleSlot: (args, cache, info) => {
      const begin = cache.resolve(`SectionSlot:${args.input.scheduleSlotID}`, 'begin') || ''
      const date = begin.slice(0, 10)

      return {
        __typename: 'SectionSlot',
        id: args.input.scheduleSlotID,
        begin: `${date} ${args.input.begin}:00`,
        end: `${date} ${args.input.end}:00`
      }
    },
    updateLeadScheduleSlot: (args, cache, info) => {
      const begin = cache.resolve(`SectionSlotLead:${args.input.leadScheduleSlotID}`, 'begin') || ''
      const date = begin.slice(0, 10)

      const fistName = args.input.acceptedUserID ? cache.resolve(`User:${args.input.acceptedUserID}`, 'fistName') : ''
      const surname = args.input.acceptedUserID ? cache.resolve(`User:${args.input.acceptedUserID}`, 'surname') : ''

      return {
        __typename: 'SectionSlotLead',
        id: args.input.leadScheduleSlotID,
        ...(args.input.begin ? {begin: `${date} ${args.input.begin}:00`} : {}),
        ...(args.input.end ? {end: `${date} ${args.input.end}:00`} : {}),
        ...(args.input.acceptedUserID ? {
          acceptedUser: {
            __typename: 'User',
            id: args.input.acceptedUserID,
            fistName,
            surname
          }
        } : {}),
      }
    },
    acceptUserForSectionSlot: (args, cache, info) => {
      const acceptedSlots= cache.resolve(`User:${args.input.userID}`, 'acceptedSlots') || []
      return {
        __typename: 'User',
        id: args.input.userID,
        acceptedSlots: [
          ...acceptedSlots,
          {
            __typename: 'SectionSlot',
            id: args.input.sectionSlotID,
            acceptedUser: {
              __typename: 'User',
              id: args.input.userID,
            }
          }
        ]
      }
    },
    dropUserForSectionSlot: (args, cache, info) => {
      const acceptedSlots= cache.resolve(`User:${args.input.userID}`, 'acceptedSlots') || []
      console.log(acceptedSlots)
      return {
        __typename: 'User',
        id: args.input.userID,
        acceptedSlots: acceptedSlots.filter(slot => slot !== `SectionSlot:${args.input.sectionSlotID}`)
      }
    },
    updateStatusLabel: (args, cache, info) => {
      return {
        __typename: 'StatusLabel',
        id: args.input.statusLabelID,
        ...(args.input.title ? {title: args.input.title} : {}),
        ...(args.input.color ? {color: args.input.color} : {})
      }
    },
    checkinUser: (args, cache, info) => {
      if (args.input.checkinItemID) {
        const list = cache.resolve(`SeasonCheckin:${args.input.seasonCheckinID}`, 'seasonCheckinItems')

        console.log(list)
        const wasIncluded = list.findIndex(item => item.includes(args.input.checkinItemID)) >= 0

        console.log('was included ', wasIncluded ? '' : [...list, `SeasonCheckinItem:${args.input.seasonCheckinID}`])

        return {
          __typename: 'SeasonCheckin',
          id: args.input.seasonCheckinID,
          seasonCheckinItems: wasIncluded
            ? list.filter(item => !item.includes(args.input.checkinItemID))
            : [
              ...list,
              {
                __typename: 'SeasonCheckinItem',
                id: args.input.checkinItemID,
                checkinTime: `${new Date().toISOString().slice(0, 10)} ${new Date().toLocaleTimeString('de', {hour: '2-digit', minute: '2-digit', second: '2-digit'})}`
              }
            ]
        }
      }

      return {
        __typename: 'SeasonCheckin',
        id: args.input.seasonCheckinID,
        checkinTime: !cache.resolve(`SeasonCheckin:${args.input.seasonCheckinID}`, 'checkinTime')
          ? `${new Date().toISOString().slice(0, 10)} ${new Date().toLocaleTimeString('de', {hour: '2-digit', minute: '2-digit', second: '2-digit'})}`
          : null
      }
    }
  },
  updates: {
    Mutation: {
      addUserNote(result, args, cache, _info) {
        cache.updateQuery({ query: readUserNotesQuery, variables: { input: { userID: args.input.userID }} }, data => {
          if (!result.addUserNote) {
            return {
              readUserNotes: [
                ...data.readUserNotes.filter(item => item !== null),
              ]
            }
          }

          return {
            readUserNotes: [
              ...data.readUserNotes.filter(item => item !== null),
              result.addUserNote
            ]
          }
        });

        cache.updateQuery({ query: readOneUserQuery, variables: { input: { userID: args.input.userID }} }, data => {
          return {
            readOneUser: {
              ...(data?.readOneUser || {}),
              userNoteAmount: cache.resolve('Query', 'readUserNotes', { input: { userID: args.input.userID }}).length
            }
          }
        })
      },
      acceptUserForSectionSlotAndDuplicate(result, args, cache, _info) {
        cache.updateQuery({ query: readSectionSlotsPerDateBySectionQuery, variables: { input: { sectionID: args.input.sectionID, date: result.acceptUserForSectionSlotAndDuplicate.begin.slice(0, 10) } } }, data => {
          return {
            readSectionSlotsPerDateBySection: [
              ...data.readSectionSlotsPerDateBySection,
              result.acceptUserForSectionSlotAndDuplicate
            ]
          }
        });
      },
      addPass(result, args, cache, _info) {
        cache.updateQuery({ query: readAllPassesQuery }, data => {
          return {
            readAllPasses: [
              ...data.readAllPasses.filter(item => item !== null),
              result.addPass
            ]
          }
        });
      },
      addScheduleSlotToRole(result, args, cache, _info) {
        if (result.addScheduleSlotToRole) {
          let date = args.input.date;
          const isoDate = date.split(".").reverse().join("-");

          cache.updateQuery({
            query: readSectionSlotsByRoleQuery,
            variables: {input: {roleID: args.input.roleID, date: isoDate}}
          }, () => {
            return {
              readSectionSlotsByRole: [
                ...result.addScheduleSlotToRole
              ]
            }
          });
        }
      },
      removeScheduleSlotFromRole(result, args, cache, _info) {
        if (result.removeScheduleSlotFromRole) {
          let date = args.input.date;
          let sectionID = args.input.sectionID

          cache.updateQuery({
            query: readSectionSlotsPerDateBySectionQuery,
            variables: {input: {date, sectionID}}
          }, () => {
            return {
              readSectionSlotsPerDateBySection: [
                ...result.removeScheduleSlotFromRole
              ]
            }
          });
        }
      },
      createGuestListItem(result, args, cache, _info) {
        if (result.createGuestListItem) {
          cache.updateQuery({query: readGuestListForCurrentSeasonQuery,}, () => {
            return {
              readGuestListForCurrentSeason: [
                ...cache.resolve('Query', 'readGuestListForCurrentSeason'),
                result.createGuestListItem
              ].sort((a, b) => {
                const aSurname = cache.resolve(a, 'surname').toLowerCase()
                const bSurname = cache.resolve(b, 'surname').toLowerCase()
                const cmp = aSurname.localeCompare(bSurname)

                if (cmp === 0) {
                  const aFirstname = cache.resolve(a, 'firstName').toLowerCase()
                  const bFirstname = cache.resolve(b, 'firstName').toLowerCase()
                  return aFirstname.localeCompare(bFirstname)
                }

                return cmp
              })
            }
          });
        }
      },
      deleteGuestListItem(result, args, cache, _info) {
        if (result.deleteGuestListItem) {
          cache.updateQuery({query: readGuestListForCurrentSeasonQuery,}, () => {
            return {
              readGuestListForCurrentSeason: [
                ...result.deleteGuestListItem
              ]
            }
          });
        }
      },
      splitScheduleSlot(result, args, cache, _info) {
        if (result.splitScheduleSlot) {
          cache.updateQuery({query: readSectionSlotsByRoleQuery, variables: {input: { date: args.input.date, roleID: args.input.roleID }}}, () => {
            return {
              readSectionSlotsByRole: [
                ...result.splitScheduleSlot
              ]
            }
          });
        }
      },
      duplicateScheduleSlot(result, args, cache, _info) {
        const slotID = args.input.scheduleSlotID
        const begin = cache.resolve(`SectionSlot:${slotID}`, 'begin')

        if (result.duplicateScheduleSlot) {
          cache.updateQuery({query: readSectionSlotsByRoleQuery, variables: {input: { date: begin.slice(0, 10), roleID: args.input.roleID }}}, () => {
            return {
              readSectionSlotsByRole: [
                ...result.duplicateScheduleSlot
              ]
            }
          });
        }
      },
      addScheduleSlotLeadToRole(result, args, cache, _info) {
        if (result.addScheduleSlotLeadToRole) {
          let date = args.input.date;
          const isoDate = date.split(".").reverse().join("-");

          cache.updateQuery({
            query: readSectionLeadSlotsByRoleQuery,
            variables: {input: {roleID: args.input.roleID, date: isoDate}}
          }, () => {
            return {
              readSectionLeadSlotsByRole: [
                ...result.addScheduleSlotLeadToRole
              ]
            }
          });
        }
      },
      dropUserForSectionSlot(result, args, cache, _info) {
        if (result.dropUserForSectionSlot) {
          cache.writeFragment(
            sectionSlotFragment,
            {
              id: args.input.sectionSlotID,
              acceptedUser: null
            }
          );
        }
      }
    },
  }
});

export default cache;
