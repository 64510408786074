import { ChevronRightIcon } from "@heroicons/react/20/solid";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { NavLink } from "react-router-dom";
import { classNames } from "../_helpers";

type PropTypes = {
  title: string;
  href: string;
  pageLink: string;
  subtitle?: string | React.ReactElement;
  showArrow?: boolean;
  loading?: boolean;
}

export const ListItem = ({title, subtitle, pageLink, href, showArrow, loading}: PropTypes) => (
  <li>
    {loading ? (
      <div className={'flex items-center space-x-4 py-4'}>
        <div className="min-w-0 flex-1 pl-4">
          <p className="truncate text-sm font-medium text-gray-900">
            <Skeleton width={180} height={20} />
          </p>
          {subtitle && (
            <p className="truncate text-sm text-gray-500">
              <Skeleton width={120} height={15} />
            </p>
          )}
        </div>
        {showArrow && (
          <div className={'pr-4 text-gray-400'}>
            <ChevronRightIcon className={'h-5 w-5'} />
          </div>
        )}
      </div>
    ): (
      <NavLink
        to={`${pageLink}${href}/`}
        className={({ isActive }) => classNames(
        'flex items-center space-x-4 py-4 cursor-pointer hover:bg-gray-50',
          isActive ? 'bg-gray-100' : ''
        )}>
        {/*<div className="flex-shrink-0">*/}
        {/*  <img className="h-8 w-8 rounded-full" alt="" />*/}
        {/*</div>*/}
        <div className="min-w-0 flex-1 pl-4">
          <p className="truncate text-sm font-medium text-gray-900">{title}</p>
          {subtitle && (
            <p className="truncate text-sm text-gray-500">{subtitle}</p>
          )}
        </div>
        {showArrow && (
          <div className={'pr-4 text-gray-400'}>
            <ChevronRightIcon className={'h-5 w-5'} />
          </div>
        )}
      </NavLink>
    )}
  </li>
)
