import { CheckIcon, PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useMemo, useState } from "react";
import { useQuery } from "urql";
import { APP_CHECKIN, APP_VIEW_USER_CHECKIN } from "../../../_constants/permissions";
import { readCheckinByUserIDQuery } from "../../../_lib/graphql/queries";
import { Dialog } from "../../../_ui/Dialog";
import { User } from "../../../types/graphql/User";
import { CheckinDialog } from "../../CheckinDialog";
import { EditCheckinDates } from "../../EditCheckinDates";
import { useMyself } from "../../UserContext";

type PropTypes = {
  user?: User
}

export const Checkin = ({user}: PropTypes) => {
  const { canAccess } = useMyself() || {}
  const [dialogOpen, setDialogOpen] = useState(false)
  const [checkinDialogOpen, setCheckinDialogOpen] = useState(false)

  const [userResult] = useQuery({
    query: readCheckinByUserIDQuery,
    variables: {
      input: {
        userID: user?.id,
      }
    },
    pause: !user?.id || !checkinDialogOpen
  })

  const arrivalOutput = useMemo(() => {
    if (user?.currentSeasonCheckin?.arrivalDate && user?.currentSeasonCheckin?.arrivalTime) {
      const date = new Date(`${user?.currentSeasonCheckin?.arrivalDate?.replace(/-/g, '/')} ${user?.currentSeasonCheckin?.arrivalTime}`)
        .toLocaleString('de-DE', {
          weekday: 'short',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })

      return `${date} Uhr`
    } else if (user?.currentSeasonCheckin?.arrivalDate) {
      return new Date(`${user?.currentSeasonCheckin?.arrivalDate?.replace(/-/g, '/')}`)
        .toLocaleDateString('de-DE', {
          weekday: 'short',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
    }

    return <>&mdash;</>
  }, [user])

  const departureOutput = useMemo(() => {
    if (user?.currentSeasonCheckin?.departureDate && user?.currentSeasonCheckin?.departureTime) {
      const date = new Date(`${user?.currentSeasonCheckin?.departureDate?.replace(/-/g, '/')} ${user?.currentSeasonCheckin?.departureTime}`)
        .toLocaleString('de-DE', {
          weekday: 'short',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })

      return `${date} Uhr`
    } else if (user?.currentSeasonCheckin?.departureDate) {
      return new Date(`${user?.currentSeasonCheckin?.departureDate?.replace(/-/g, '/')}`)
        .toLocaleDateString('de-DE', {
          weekday: 'short',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
    }

    return <>&mdash;</>
  }, [user])

  return (
    <div className={'sm:grid sm:grid-cols-3 sm:gap-2'}>
      <div className={'text-[14px]'}>
        <div className={'flex items-center gap-2 cursor-pointer'} onClick={() => setCheckinDialogOpen(true)}>
          Check-In
          {user?.currentSeasonCheckin && user?.currentSeasonCheckin?.checkinTime !== null ? (
            <div className={'flex items-center justify-center w-[18px] h-[18px] rounded-full bg-lime-200 ring-1 ring-inset ring-lime-600 text-lime-900'}>
              <CheckIcon className={'w-3 h-3'} />
            </div>
          ) : (
            <div className={'flex items-center justify-center w-[18px] h-[18px] rounded-full bg-red-200 ring-1 ring-inset ring-red-600 text-red-900'}>
              <XMarkIcon className={'w-3 h-3'} />
            </div>
          )}
        </div>


        {(canAccess && canAccess(APP_CHECKIN)) && (
          <CheckinDialog
            loading={userResult.fetching}
            seasonCheckin={userResult.data?.readCheckinByUserID}
            open={checkinDialogOpen}
            onClose={() => {
              setCheckinDialogOpen(false)
            }}
          />
        )}
      </div>
      <div className={'col-span-2'}>
        <div className={'grid text-sm space-y-2 sm:space-y-0 sm:grid-cols-2 sm:gap-2 bg-red'}>
          <div>
            <div className={'flex gap-2 mt-4 sm:mt-0'}>
              <h5 className={'font-bold mb-1'}>Anreise</h5>
              {canAccess && canAccess(APP_VIEW_USER_CHECKIN) && (
                <button
                  className={'flex justify-center disabled:text-gray-400 focus:outline-0'}
                  onClick={() => setDialogOpen(true)}
                >
                  <PencilIcon className={'w-4 h-4'} />
                </button>
              )}
            </div>
            <span>{arrivalOutput}</span>
            {/*<span className={'italic'}>unbekannt</span>*/}
          </div>
          <div>
            <div className={'flex gap-2'}>
              <h5 className={'font-bold mb-1'}>Abreise</h5>
              {canAccess && canAccess(APP_VIEW_USER_CHECKIN) && (
                <button
                  className={'flex justify-center disabled:text-gray-400 focus:outline-0'}
                  onClick={() => setDialogOpen(true)}
                >
                  <PencilIcon className={'w-4 h-4'} />
                </button>
              )}
            </div>
            <span>{departureOutput}</span>
          </div>
        </div>
      </div>

      {user && canAccess && canAccess(APP_VIEW_USER_CHECKIN) && dialogOpen && (
        <Dialog title={'Check-In bearbeiten'} open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <EditCheckinDates user={user} handleBack={() => setDialogOpen(false)} />
        </Dialog>
      )}
    </div>
  )
}
